// import { createStore } from 'redux'

// const initialState = {
//   sidebarShow: false,
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store

import { createStore } from 'redux';

// Initial state with both sidebar and device keys
const initialState = {
  sidebarShow: false,
  device_list: [],
  online_device_list: [],
  offline_device_list: [],
  online_device_count: 0,
  offline_device_count: 0,
  error_device_list: [],
};

// Single reducer handling all keys dynamically
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'set':
      // Spread the action's payload directly into the state
      return { ...state, ...action };
    case 'SET_DEVICES':
      return { ...state, device_list: action.payload };
    case 'UPDATE_ONLINE_DEVICES':
      return {
        ...state,
        online_device_list: action.payload,
        online_device_count: action.payload.length,
      };
    case 'UPDATE_OFFLINE_DEVICES':
      return {
        ...state,
        offline_device_list: action.payload,
        offline_device_count: action.payload.length,
      };
    case 'SET_ERROR_DEVICES':
      return { ...state, error_device_list: action.payload };
    default:
      return state;
  }
};

// Create the Redux store
const store = createStore(rootReducer);

export default store;
