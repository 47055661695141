import axios from "axios";

// let token = 'tituw3958589'

let prefixURL = process.env.REACT_APP_URL_LOGIN

export function userLogin(requestBody) {
    // requestBody.token = token
    return axios.post(prefixURL + `/user/login` , requestBody).then(res=>res).catch(err=>err)
}

export function logoutUser(requestBody) {
    // let requestBody = {}
    // requestBody.token = token
    // requestBody.session = '123456789'
    return axios.post(prefixURL + `/user/logout` , requestBody).then(res=>res).catch(err=>err)
}