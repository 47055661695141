import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import LogoBlue from '../assets/images/logo-blue.png'
import LoginBG from '../assets/images/login.png'
import './styles/styles.css'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { emailPattern } from './../common/utilities';

import infiniteLoader from '../assets/images/infinite_loader.gif'
import { userLogin } from './actionMethods/actionMethods';


function Login (props) {

  const [emailID, updateEmailID] = useState('')
  const [password, updatePassword] = useState('')

  const [isEmailValid, updateIsEmailValid] = useState(true)
  const [isPasswordEmpty, updateIsPasswordEmpty] = useState(false)

  const [somethingWentWrongFlag, updateSomethingWrongWentFlag] = useState(false)
  const [errorMessage, updateErrorMessage] = useState('')
  const [successMessage, updateSucessMessage] = useState('')
  const [isLoading, updateIsLoading] = useState(false)
  
  function randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
  }  

  function handleSubmit(event){
      event.preventDefault()

      if (emailID == '') {
        updateIsEmailValid(false)
      }

      if (password == '') {
        updateIsPasswordEmpty(true)
      }

      if (emailID && password) {

        let isValid = emailPattern.test(emailID)

        if (isValid) {
          updateIsLoading(true)
          updateIsPasswordEmpty(false)
          updateIsEmailValid(true)
          
          let requestBody = {}
          requestBody.username = emailID
          requestBody.password = password
          requestBody.token= randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
          
          //// // //console.log("Request", requestBody);

          // localStorage.setItem('isLoggedInSafeAir', true)        
          // props.history.push('/dashboard')      

          userLogin(requestBody).then(res => {
            updateIsLoading(false)
            if (res && res.status >= 200 && res.status <= 299) {
              //// // //console.log("Status", res.data.status)
              if (res.data && res.data.status == 200) {
                // if(res.data.data!=null) {
                //   res.data.data.lat = "17.4121531";
                //   res.data.data.lng = "78.1278513";
                // }
                localStorage.setItem('userLoginDetailsSafeAir', JSON.stringify(res.data.data))
                localStorage.setItem('isLoggedInSafeAir', true)
                localStorage.setItem('userLoginSafeAirToken',requestBody.token)
                props.history.push('/dashboard')
              } else {
                updateSomethingWrongWentFlag(true)
                updateErrorMessage(res.data.message)

                setTimeout(() => {
                    updateSomethingWrongWentFlag(false)
                }, 3000);
              }
            }
    
            //// // //console.log("response",res);
            // localStorage.setItem('isLoggedInSafeAir', true)        
            // props.history.push('/dashboard')      
          }).catch(err => {
            updateSomethingWrongWentFlag(true)
            updateErrorMessage('Username and password do not match')
          })


        }
        else {
          updateIsEmailValid(false)
        }
      }
  } 
   const handleNavigationStateChange = (navState) => {
    // Extracting route parameters from the URL
    const url = navState.url;
    const params = getUrlParams(url);

    // Now you can use these parameters in your application
    console.log(params);
  };
  
function handleClick() {
  // Perform some action
  const data = { action: 'some_action', payload: 'some_data' };
  window.postMessage(data, '*'); // '*' allows posting messages to any origin
}

  return (
    <div className="login-form-extend bg-white min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center align-items-center">
          <CCol md={8}>
            <div className="signin-div" onClick={handleClick}>
              <img src={LogoBlue} className="logo-sgnin"/>
            </div>
            <div className="sign-in graphic">
              <img src={LoginBG}/>
            </div>
          </CCol>
          <CCol md={4} className="signin-form">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <form onSubmit={handleSubmit}>
                    <h1 className="mb-6">Login</h1>

                    <label>User name</label>
                    <CInputGroup className="mb-1">                    
                      <CFormInput placeholder="Username" autoComplete="username" value={emailID} onChange={(e) => handleEmailID(e.target.value)} maxLength={150}/>  
                    </CInputGroup>
                    {
                      !isEmailValid ? <div className="dangerColor">Please Enter Valid Email ID !</div> : ''
                    }

                    <label className="login_spacing_component ">Password</label>
                    <CInputGroup className="mb-1 login_password">
                     
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        value={password}                                                            onChange={(e) => handlePassword(e.target.value)} 
                        maxLength="150"
                      />
                    </CInputGroup>
                    {
                      isPasswordEmpty ? <div className="dangerColor">Please Enter Password !</div> : ''
                    }


                    <label className="label-small label-forget mb-4" style={{cursor:"pointer"}} onClick={()=>props.history.push('/reset-password')}>Forgot Password?</label>
                    <CRow>
                    <CCol md={12} className="" style={{textAlign:"center"}} >
                      {
                        isLoading ?
                          <img src={infiniteLoader} /> :<>
                          <input type="submit" className="button button-primary  btn-bigger" value="Login"/>
                          <label className="login_spacing_component" style={{cursor:"pointer"}} onClick={()=>props.history.push('/signup')}><span style={{color:"#000"}}>Don't have an Account? </span>Signup</label>

                          </>
                      }                      
                      </CCol>
                    </CRow>

                    {
                      somethingWentWrongFlag ?
                          <label className="dangerColor text-center">{errorMessage}</label> : ''
                    }

                  </form>
                                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )

  function handleEmailID(value) {
    updateEmailID(value)
  }
  
  function handlePassword(value) {
    updatePassword(value)
    updateIsPasswordEmpty(false)
  }
  
}
export default Login
